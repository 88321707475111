import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../db/banco";
import IAnunciante from "../../types/IAnunciante";
import ICriarCampanha from "../../types/ICriarCampanha";
import IInfluenciador from "../../types/IInfluenciador";
import ILinkGeradoISM from "../../types/ILinkGeradoISM";
import { ITransactionID } from "../../types/ITransactionID";

export const obterTodosAnunciantes = async (): Promise<IAnunciante[]> => {
    const useCollectionRefAnunciante = collection(db, "anunciante");
    const dataBD = await getDocs(useCollectionRefAnunciante);
    const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }) as IAnunciante);

    return todosAnunciantes;
};

export const obterTodosInfluenciadores = async (): Promise<IInfluenciador[]> => {
    const useCollectionRefInfluenciedor = collection(db, "influenciador");
    const dataBD = await getDocs(useCollectionRefInfluenciedor);
    const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }) as IInfluenciador);

    return todosInfluenciadores;
};

export const obterTodasCampanhas = async (): Promise<ICriarCampanha[]> => {
    const useCollectionRefCriarCampanha = collection(db, "criarCampanha");
    const dataBD = await getDocs(useCollectionRefCriarCampanha);
    const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }) as ICriarCampanha);

    return todasCampanhas;
};

export const obterTodosLinksGerados = async (): Promise<ILinkGeradoISM[]> => {
    const useCollectionRefLinkGeradoISM = collection(db, "linkGeradoISM");
    const dataBD = await getDocs(useCollectionRefLinkGeradoISM);
    const todosLinksGeradosISM: ILinkGeradoISM[] = dataBD.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }) as ILinkGeradoISM);

    return todosLinksGeradosISM;
};

export const obterTodosTransactionID = async (): Promise<ITransactionID[]> => {
    const useCollectionRefTransactionID = collection(db, "transactionID");
    const dataBD = await getDocs(useCollectionRefTransactionID);
    const todosLinksGeradosISM: ITransactionID[] = dataBD.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }) as ITransactionID);

    return todosLinksGeradosISM;
};

