import React from "react";
import styles from "./CheckBox.module.css";

interface CheckboxProps {
    label: string;
    value: string;
    checked: boolean;
    onChange: (value: string, checked: boolean, state: string) => void;
    state: string
}

const Checkbox: React.FC<CheckboxProps> = ({ label, value, checked, onChange, state }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(value, event.target.checked, state);
    };

    return (
        <label className={styles.CheckBox}>
            <input
                type="checkbox"
                value={value}
                checked={checked}
                onChange={handleChange}
            />
            {label}
        </label>
    );
};

export default Checkbox;
