import axios from 'axios';

export const buscarDadosApiEstatisicas = async (
    dataInicial: string, // Parâmetro obrigatório
    dataFinal: string,   // Parâmetro obrigatório
    idCampanhaActionpay?: string, // Parâmetro opcional, movido para depois dos obrigatórios
    subId1?: string // Parâmetro opcional
) => {
    try {
        // Construção da URL da chamada da API
        const url = new URL(`https://us-central1-ism-teste.cloudfunctions.net/buscarEstatisticas`);
        url.searchParams.append('from', dataInicial);
        url.searchParams.append('till', dataFinal);

        // Adiciona o offer à URL se estiver definido
        if (idCampanhaActionpay) {
            url.searchParams.append('offer', idCampanhaActionpay);
        }

        // Adiciona o subId1 à URL se estiver definido
        if (subId1) {
            url.searchParams.append('subId1', subId1);
        }

        // Chamada para a função Firebase Functions com os parâmetros
        const response = await axios.get(url.toString());

        // Verifica se há erro na resposta
        if (!response.data.error) {
            return response.data; // Retorna os dados se não houver erro
        } else {
            return undefined; // Retorna undefined em caso de erro na resposta
        }

    } catch (error) {
        return undefined; // Retorna undefined em caso de erro na chamada da API
    }
};

export const buscarObjetivos = async (
    // Parâmetro obrigatório
    idCampanhaActionpay: string, // Parâmetro opcional, movido para depois dos obrigatórios
    
) => {
    try {
        // Construção da URL da chamada da API
        const url = new URL(`https://us-central1-ism-teste.cloudfunctions.net/verificarMetas`);
        
        // Adiciona o offer à URL se estiver definido
        if (idCampanhaActionpay) {
            url.searchParams.append('offer', idCampanhaActionpay);
        }

        
        // Chamada para a função Firebase Functions com os parâmetros
        const response = await axios.get(url.toString());

        // Verifica se há erro na resposta
        if (!response.data.error) {
            return response.data; // Retorna os dados se não houver erro
        } else {
            return undefined; // Retorna undefined em caso de erro na resposta
        }

    } catch (error) {
        return undefined; // Retorna undefined em caso de erro na chamada da API
    }
};

