import { useLocation } from "react-router-dom";
import styles from "./AdicionarNovosObjetivos.module.css";
import { useEffect, useState } from "react";
import { obterTodasCampanhas } from "../../../utils/db";
import ICriarCampanha from "../../../types/ICriarCampanha";
import SellIcon from '@mui/icons-material/Sell';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import Checkbox from "../../../components/CheckBox";
import Aims from "../../Anunciante/CriarCampanha/Aims";

import { db } from "../../../db/banco";
import { doc, updateDoc } from "firebase/firestore/lite";


export default function AdicionarNovosObjetivos() {
    // Pegar parametro da campanha
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const idCampanha = params.get("id");
    const [atualiza, setAtualiza] = useState(false);
    // Obter a campanha
    const [campanha, setCampanha] = useState<ICriarCampanha[]>([]);
    useEffect(() => {
        const carregarTodasCampanhas = async () => {
            const todasCampnhas = await obterTodasCampanhas();
            const somenteEssaCampanha = todasCampnhas.filter(campanha => campanha.id === idCampanha);
            setCampanha(somenteEssaCampanha);
            somenteEssaCampanha.map(adicionarValores => {
                setVendaConfirmada(adicionarValores.vendaConfirmada);
                setCadastro(adicionarValores.cadastro);
                setInscricao(adicionarValores.inscricao);
                setPrimeiroDeposito(adicionarValores.primeiroDeposito);
                setInstalacao(adicionarValores.instalacao);
            })
        }
        carregarTodasCampanhas();
    }, [idCampanha, atualiza])
    // CheckBox
    // CheckBox
    const [selectedOptions, setSelectedOptions] = useState<{ value: string; checked: boolean, state: string }[]>([]);
    // Função para tratar mudanças nos checkboxes
    const handleCheckboxChange = (value: string, checked: boolean, state: string) => {
        setSelectedOptions((prev) => {
            if (checked) {
                // Adiciona a opção ao array se marcada
                return [...prev, { value, checked, state }];
            } else {
                // Remove a opção do array se desmarcada
                return prev.filter((option) => option.value !== value);
            }
        });
    };
    // Remove objetivos já contidos
    const [optionsFree, setOptionsFree] = useState<{ label: string; value: string }[]>([]);

    useEffect(() => {
        const optionsCheckBoxAimCreate: { label: string; value: string }[] = []; // Array de opções

        campanha.map((insetOptions) => {
            if (insetOptions.vendaConfirmada === "") optionsCheckBoxAimCreate.push({ label: "Venda confirmada", value: "Venda confirmada" });
            if (insetOptions.cadastro === "") optionsCheckBoxAimCreate.push({ label: "Cadastro", value: "Cadastro" });
            if (insetOptions.inscricao === "") optionsCheckBoxAimCreate.push({ label: "Inscrição", value: "Inscrição" });
            if (insetOptions.primeiroDeposito === "") optionsCheckBoxAimCreate.push({ label: "Primeiro depósito", value: "Primeiro depósito" });
            if (insetOptions.instalacao === "") optionsCheckBoxAimCreate.push({ label: "Instalação", value: "Instalação" });
        });

        setOptionsFree(optionsCheckBoxAimCreate); // Atualiza o estado com as opções geradas

    }, [campanha, atualiza]);

    const [vendaConfirmada, setVendaConfirmada] = useState("");
    const [cadastro, setCadastro] = useState("");
    const [inscricao, setInscricao] = useState("");
    const [primeiroDeposito, setPrimeiroDeposito] = useState("");
    const [instalacao, setInstalacao] = useState("");

    const handleSet = (value: string) => {
        switch (value) {
            case "Venda confirmada":
                return setVendaConfirmada;
            case "Cadastro":
                return setCadastro;
            case "Inscrição":
                return setInscricao;
            case "Primeiro depósito":
                return setPrimeiroDeposito;
            case "Instalação":
                return setInstalacao;
            default:
                return () => { };
        }
    };

    const handleValidation = (value: string) => {
        switch (value) {
            case "Venda confirmada":
                return vendaConfirmada;
            case "Cadastro":
                return cadastro;
            case "Inscrição":
                return inscricao;
            case "Primeiro depósito":
                return primeiroDeposito;
            case "Instalação":
                return instalacao;
            default:
                return "";
        }
    };

    function adicionarNovosObjetivos() {
        const novosObjetivos = {
            vendaConfirmada,
            cadastro,
            inscricao,
            primeiroDeposito,
            instalacao
        }

        editarObjetivo(novosObjetivos);
    }

    async function editarObjetivo(novosDados: any) {
        try {
            if (idCampanha) {
                const criarCampanhaRef = doc(db, "criarCampanha", idCampanha);

                await updateDoc(criarCampanhaRef, novosDados);

                alert("Objetivos adicionados à sua campanha!");
            }
            setAtualiza(!atualiza)

        } catch (error) {
            alert("Nao deu certo")
        }
    }

    return (
        <section className={styles.AdicionarNovosObjetivos}>
            {campanha.map(campanha => (
                <>
                    <h2> Adicionar novos objetivos na campanha: {campanha.nomeDaCampanha}. </h2>

                    <aside>
                        <h3> Objetivos já escolhidos: </h3>

                        <ul>
                            {campanha.vendaConfirmada && <li> <SellIcon /> Venda confirmada </li>}
                            {campanha.cadastro && <li> <AssignmentIndIcon /> Cadastro </li>}
                            {campanha.inscricao && <li> <HowToRegIcon /> Inscrição </li>}
                            {campanha.primeiroDeposito && <li> <LocalAtmIcon /> Primeiro depósito </li>}
                            {campanha.instalacao && <li> <InstallMobileIcon /> Instalação </li>}
                        </ul>

                    </aside>

                    {campanha.vendaConfirmada && campanha.cadastro && campanha.inscricao &&
                        campanha.primeiroDeposito && campanha.instalacao ?
                        <h4> Você já tem todos objetivos adicionados à sua campanha! </h4> :


                        <section>
                            <label> Adicionar novos objetivos </label>
                            <div className={styles.CheckBox}>
                                {optionsFree.map((option) => (
                                    <Checkbox
                                        key={option.value}
                                        label={option.value}
                                        value={option.value}
                                        checked={selectedOptions.some((selected) => selected.value === option.value && selected.checked)}
                                        onChange={handleCheckboxChange} // Passa a função de onChange do componente pai
                                        state=""
                                    />
                                ))}
                            </div>

                            {selectedOptions.map(obj => (
                                <Aims
                                    title={obj.value}
                                    control={handleSet(obj.value)}
                                    value={handleValidation(obj.value)}
                                />
                            ))}

                            <button onClick={adicionarNovosObjetivos}> Adicionar </button>

                        </section>
                    }
                </>
            ))}

        </section>
    )
}