import React from 'react';

interface FormatValuesProps {
  valor: string;
}

const FormatValues: React.FC<FormatValuesProps> = ({ valor }) => {
  const formatarValor = (valor: string): string => {
    if (valor.includes('R$')) {
      return valor.includes(',') ? valor : `${valor},00`; // Se já tiver vírgula, mantemos, senão adicionamos ,00
    } else {
      return `${valor}%`; // Adiciona % se não tiver "R$"
    }
  };

  return <span>{formatarValor(valor)}</span>;
};

export default FormatValues;
