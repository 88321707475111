import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import React, { useState, useEffect } from "react";
import BotaoSubmit from "../../../components/BotaoSubmit";
import CampoEscolha from "../../../components/CampoEscolha";
import { db } from "../../../db/banco";
import autenticaStore from "../../../stores/autentica.store";
import ICriarCampanha from "../../../types/ICriarCampanha";
import Tooltip from '@mui/material/Tooltip';
import styles from "./MinhasCampanhas.module.css";
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Modal, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styleModal } from "../../../utils/estilos";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import { mascaraDinheiro } from "../../../utils/mascaras";
import IAnunciante from "../../../types/IAnunciante";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import { listaDeStatusCampanhasAnunciante, listaTiposDeCampanha } from "../../../utils/listas";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IChatPropostas from "../../../types/ICharPropostas";
import QueueIcon from '@mui/icons-material/Queue';

export default function MinhasCampanhas() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    // email usado como id
    const email = autenticaStore.getEmail();
    // Filtro
    const [status, setStatus] = useState("");
    // campanha
    const [campanha, setCampanha] = useState<ICriarCampanha[]>([]);
    const [campanhaAux, setCampanhaAux] = useState<ICriarCampanha[]>([]);
    const [nomeDaCampanha, setNomeDaCampanha] = useState("");
    // tabela
    const [tabela, setTabela] = useState(true);
    // Controle do modal
    const [open, setOpen] = React.useState(false);
    const [openn, setOpenn] = React.useState(false);
    const [openSaldo, setOpenSaldo] = React.useState(false);
    // id
    const [idCampanha, setIdCampanha] = useState("");
    // Atualizar lista
    const [atualizaLista, setAtualizaLista] = useState(false);
    // Controla mensagem do saldo
    const [campanhaComSaldo, setCampanhaComSaldo] = useState(true);
    // Adicionar saldo em campanha
    const [orcamento, setOrcamento] = useState("");
    // alerta de saldo
    const [alertaSaldo, setAlertaSaldo] = useState(false);
    const [alertaSaldoInferior, setAlertaSaldoInferior] = useState(false);
    // anunciante
    const [idAnunciante, setIdAnunciante] = useState("");
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);
    const [idParaEditar, setIdParaEditar] = useState("");
    const [saldoAnterior, setSaldoAnterior] = useState(0);
    const [saldoCarteira, setSaldoCarteira] = useState(0);
    // chat
    const [idChat, setIdChat] = useState("");
    const [saldoChat, setSaldoChat] = useState(0);

    // Responsivo
    useEffect(() => {
        function largura() {
            if (window.innerWidth < 650) setTabela(false);
        }
        largura();
    }, [])

    // Obtem e atualiza os dadoa
    const useCollectionRef = collection(db, "criarCampanha");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha);
            const campanhasDesseAnunciante = todasCampanhas.filter(cam => cam.anuncianteEmail === email);
            setCampanha(campanhasDesseAnunciante);
            setCampanhaAux(campanhasDesseAnunciante);
        }
        obterAnunciantes();
    }, [atualizaLista])

    const useCollectionReff = collection(db, "anunciante");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const filtrado: IAnunciante[] = todosAnunciantes.filter(filtrado => filtrado.email === email);

            filtrado.map(esseAnunciante => {
                setSaldoCarteira(esseAnunciante.saldo);
                setIdAnunciante(esseAnunciante.id);
            })
        }

        obterAnunciantes();
    }, [atualizaLista]);

    // banco de dados
    const useCollectionChatRef = collection(db, "chatPropostas");
    useEffect(() => {
        const obterChat = async () => {
            // pega o chat proposta certo
            const dataBD = await getDocs(useCollectionChatRef);
            const todosChamados = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas));

            const filtrado = todosChamados.filter(porIDCampanha => porIDCampanha.idCampanha === idCampanha);

            filtrado.map(pegarIdChat => {
                setIdChat(pegarIdChat.id);
                setSaldoChat(pegarIdChat.saldoCampanha);
            })
        };

        obterChat();


    }, [idCampanha]);

    // FILTRO
    function filtrar(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        let resultadoFiltrado: ICriarCampanha[] = [...campanhaAux]; // Cria uma cópia do array original

        if (status !== "todas") {
            resultadoFiltrado = resultadoFiltrado.filter(
                (filtradoStatus) => filtradoStatus.status === status
            );
        } else {
            resultadoFiltrado = campanhaAux
        }

        setCampanha(resultadoFiltrado); // Define o estado da campanha com o resultado filtrado
    }

    // TABELA
    const fechaModal = () => {
        setOpen(false);
        setAtualizaLista(!atualizaLista);
    }
    const fechaModall = () => {
        setOpenn(false);
        setAtualizaLista(!atualizaLista);
    }

    const fechaModalSaldo = () => {
        setOpenSaldo(false);
        setAtualizaLista(!atualizaLista);
        setAlertaSaldo(false)
        setAlertaSaldoInferior(false)
    }

    const abreModalDesativar = (id: string) => {
        setOpen(true);
        setIdCampanha(id);
    }

    const abreModalAtivar = (id: string, saldo: number, nome: string) => {
        if (saldo > 0) setCampanhaComSaldo(true);
        else setCampanhaComSaldo(false)
        setSaldoAnterior(saldo);
        setNomeDaCampanha(nome);
        setOpenn(true);
        setIdCampanha(id);
    }

    async function editarCampanha(novosDados: any) {
        try {
            const criarCampanhaDocRef = doc(db, "criarCampanha", idCampanha);

            await updateDoc(criarCampanhaDocRef, novosDados);
            fechaModal();
            fechaModall();

        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function editarAnunciante(novosDados: any) {
        try {
            const criarCampanhaDocRef = doc(db, "anunciante", idAnunciante);

            await updateDoc(criarCampanhaDocRef, novosDados);
            fechaModal();

        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function desativarCampanha() {
        const campanhaCompleta = {
            status: "desativada"
        }
        editarCampanha(campanhaCompleta);
    }

    async function ativarCampanha() {
        const campanhaCompleta = {
            status: "ativa",
            jaAtivadaAntes: true
        }
        editarCampanha(campanhaCompleta);
    }

    function adicionarOrcamento() {


        let orcamentoLimpo = orcamento.replace(/[^\d,]/g, '');

        if (Number(orcamentoLimpo) < 100) {
            alert("Orçamento mínimo de R$100,00");
            setAlertaSaldoInferior(true);
            return;
        }

        if (Number(orcamentoLimpo) > saldoCarteira) {
            alert("O orçamento está maior que seu saldo!");
            setAlertaSaldo(true);


        } else {
            setAlertaSaldo(false);

            // cáculo do orçamento de cada campanha
            let enviarOrcamento = saldoAnterior + Number(orcamentoLimpo);
            // saldo da carteira
            let enviarSaldo = saldoCarteira - Number(orcamentoLimpo);

            // alert(`${saldoCarteira} - ${Number(orcamentoLimpo)} = ${enviarSaldo}`)

            const campanha = {
                saldoCampanha: enviarOrcamento
            }

            const anunciante = {
                saldo: enviarSaldo
            }

            const chat = {
                saldoCampanha: saldoChat + enviarOrcamento
            }

            editarCampanha(campanha);
            editarAnunciante(anunciante);
            editarChamado(chat)
            criarExtrato();
            fechaModalSaldo();
            alert("Saldo adicionado com sucesso!");
        }
    }

    async function editarChamado(novosDados: any) {
        try {
            if (idChat) {
                const chamadoDocRef = doc(db, "chatPropostas", idChat);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    const abreModalSaldo = (id: string, saldoAntes: number, nome: string) => {
        setOpenSaldo(true);
        setAtualizaLista(!atualizaLista);
        setOrcamento("");
        setIdCampanha(id);
        setSaldoAnterior(saldoAntes);
        setNomeDaCampanha(nome);
    }

    function trocarModal() {
        fechaModall();
        abreModalSaldo(idCampanha, saldoAnterior, nomeDaCampanha);
    }

    // extrato
    // banco de dados
    const useCollectionRefff = collection(db, "extratoCampanha")
    async function criarExtrato() {
        const data = new Date()
        const parteDesejada = data.toLocaleDateString();

        const extrato = await addDoc(useCollectionRefff, {
            email,
            nomeCampanha: nomeDaCampanha,
            data: parteDesejada,
            valor: orcamento,
            positivo: true,
        })


    }

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.MinhasCampanhas}>
                <h2> Minhas campanhas </h2>

                <form className={styles.FiltroCompleto} onSubmit={filtrar}>
                    <CampoEscolha
                        titulo="Status"
                        lista={listaDeStatusCampanhasAnunciante}
                        icone={<PauseCircleOutlineOutlinedIcon fontSize="large" />}
                        controle={setStatus}
                        valorPadrao="Status da campanha"
                    />

                    <BotaoSubmit classe="btn btn-secondary">
                        <SearchOutlinedIcon fontSize="large" />
                        Pesquisar
                    </BotaoSubmit>
                </form>

                <>
                    <h2> Resultado da pesquisa </h2>
                    {tabela ? <table className="table table-light table-striped text-center ">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white"> Nome </th>
                                <th scope="col" className="text-white"> R$ </th>
                                <th scope="col" className="text-white"> Status </th>
                                <th scope="col" className="text-white"> Criado em: </th>
                                <th scope="col" className="text-white"> Ações </th>
                            </tr>
                        </thead>
                        <div>
                            <Modal
                                open={open}
                                onClose={fechaModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={styleModal}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Deseja desativar campanha {nomeDaCampanha}?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Tem certeza que deseja desativar esta campanha ? Ela irá desaparecer
                                        da lista dos influenciadores.
                                        <div className={styles.divBotoesModal}>
                                            <button className="btn btn-danger" onClick={desativarCampanha}> Sim </button>
                                            <button className="btn btn-primary" onClick={fechaModal}> Não </button>
                                        </div>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                        <div>
                            <Modal
                                open={openn}
                                onClose={fechaModall}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={styleModal}>
                                    {campanhaComSaldo ? <> <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Deseja ativar a campanha {nomeDaCampanha}?
                                    </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Ao ativar a campanha, ela irá aparecer para todos os outros
                                            influenciadores, novamente! E você não poderá editar essa campanha.

                                            <div className={styles.divBotoesModal}>
                                                <button className="btn btn-success" onClick={ativarCampanha}> Sim </button>
                                                <button className="btn btn-primary" onClick={fechaModall}> Não </button>
                                            </div>
                                        </Typography> </> : <> <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Você não possui saldo na campanha {nomeDaCampanha}!
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Para ativar a campanha, você precisa adicionar saldo.
                                            Deseja adicionar saldo nessa campanha?

                                            <div className={styles.divBotoesModal}>
                                                <button className="btn btn-success" onClick={trocarModal}> Sim </button>
                                                <button className="btn btn-primary" onClick={fechaModall}> Não </button>
                                            </div>
                                        </Typography> </>}
                                </Box>
                            </Modal>
                        </div>
                        <div>
                            <Modal
                                open={openSaldo}
                                onClose={fechaModalSaldo}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={styleModal}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Seu saldo é de: R$ {saldoCarteira},00
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Adicone orçamento na campanha {nomeDaCampanha}, esse valor será debitado
                                        de seu saldo.

                                        <input value={mascaraDinheiro(orcamento)} onChange={(e) => setOrcamento(e.target.value)} className={styles.InputModal} />
                                        {alertaSaldo && <p className={styles.AlertaSaldo}> Saldo insuficiente para esse orçamemento! </p>}
                                        {alertaSaldoInferior && <p className={styles.AlertaSaldo}> Orçamento mínimo de R$100,00 </p>}

                                        <div className={styles.divBotoesModal}>
                                            <button className="btn btn-success" onClick={adicionarOrcamento}> Adicionar </button>
                                            <button className="btn btn-secondary" onClick={fechaModalSaldo}> Voltar </button>
                                        </div>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                        {campanha?.map(cel => (
                            <tbody>
                                <tr>
                                    <td> <Link to={`/anunciante/detalhes-campanha?id=${cel.id}`}> {cel.nomeDaCampanha} {cel.saldoCampanha < 100 && <Tooltip title="Campanha invisível por falta de saldo." arrow classes={{ tooltip: styles.tooltip }}>
                                        <span>
                                            <VisibilityOffIcon className={styles.IconeInvisivel} />
                                        </span>
                                    </Tooltip>} </Link></td>
                                    <td> R$ {cel.saldoCampanha},00 </td>
                                    {cel.tipoDeCampanha === listaTiposDeCampanha[2] || cel.tipoDeCampanha === listaTiposDeCampanha[3]
                                        || cel.tipoDeCampanha === listaTiposDeCampanha[4] ?
                                        <td> <p className={`${!cel.codigoActionpay ? "emIntegracao" : cel.status === "ativa" ? "ativo" : "desativado"}`}> {!cel.codigoActionpay ? "Em integração" : cel.status} </p> </td> // Campanhas que tem performance
                                        :
                                        <td> <p className={`${cel.status === "ativa" ? "ativo" : "desativado"}`}> {cel.status} </p> </td> // Campanhas sem performance
                                    }

                                    <td> {cel.inicioInscricao.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </td>
                                    <td>
                                        <div className="divAcoesDesktop">
                                            <AttachMoneyIcon className={styles.IconeSaldo} onClick={() => abreModalSaldo(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} />
                                            {!cel.jaAtivadaAntes && <Link to={`/anunciante/editar-campanha?id=${cel.id}`}> <EditIcon className={styles.IconeEditar} /> </Link>}
                                            {/* {cel.status === "ativa" ? <CancelOutlinedIcon onClick={() => abreModalDesativar(cel.id)} className={styles.IconeFinalizar} /> 
                                                : <CheckCircleOutlineOutlinedIcon onClick={() => abreModalAtivar(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} className={styles.IconeAtivar} 
                                            />} */}
                                            {cel.tipoDeCampanha === listaTiposDeCampanha[2] || cel.tipoDeCampanha === listaTiposDeCampanha[3]
                                                || cel.tipoDeCampanha === listaTiposDeCampanha[4] ?
                                                <> {cel.codigoActionpay && cel.status === "ativa" && <CancelOutlinedIcon onClick={() => abreModalDesativar(cel.id)} className={styles.IconeFinalizar} />}
                                                    {cel.codigoActionpay && cel.status === "desativada" && <CheckCircleOutlineOutlinedIcon onClick={() => abreModalAtivar(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} className={styles.IconeAtivar} />}
                                                    <Link to={`/anunciante/estatisticas-da-campanha?id=${cel.codigoActionpay}&tp=anun`}> <EqualizerOutlinedIcon className={styles.IconeEstatistica} /> </Link>
                                                    {/* <Tooltip title="Adicionar novos objetivos." arrow classes={{ tooltip: styles.tooltip }}>
                                                        <Link to={`/anunciante/adicionar-novos-objetivos?id=${cel.id}`}> <QueueIcon className={styles.IconeNovosObjetivos} /> </Link>
                                                    </Tooltip> */}
                                                </>
                                                :
                                                <>
                                                    {cel.status === "ativa" && <CancelOutlinedIcon onClick={() => abreModalDesativar(cel.id)} className={styles.IconeFinalizar} />}
                                                    {cel.status === "desativada" && <CheckCircleOutlineOutlinedIcon onClick={() => abreModalAtivar(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} className={styles.IconeAtivar} />}
                                                    {/* <Link to={`/anunciante/estatisticas-de-campanha?id=${cel.codigoActionpay}`}> <EqualizerOutlinedIcon className={styles.IconeEstatistica} /> </Link> */}
                                                    
                                                </>
                                            }
                                            {/* {cel.codigoActionpay && cel.status === "ativa" && <CancelOutlinedIcon onClick={() => abreModalDesativar(cel.id)} className={styles.IconeFinalizar} />}
                                            {cel.codigoActionpay && cel.status === "desativada" && <CheckCircleOutlineOutlinedIcon onClick={() => abreModalAtivar(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} className={styles.IconeAtivar} />}
                                            <Link to={`/anunciante/estatisticas-de-campanha?id=${cel.codigoActionpay}`}> <EqualizerOutlinedIcon className={styles.IconeEstatistica} /> </Link> */}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table> : <div>
                        {campanha?.map(cel => (
                            <ul className="lista">
                                <li className="Itemlista"> . </li>

                                <li className="Itemlista"> <strong> Nome: </strong> {cel.nomeDaCampanha} {cel.saldoCampanha < 100 && <Tooltip title="Campanha invisível por falta de saldo." arrow classes={{ tooltip: styles.tooltip }}>
                                    <span>
                                        <VisibilityOffIcon className={styles.IconeInvisivel} />
                                    </span>
                                </Tooltip>} </li>
                                <li className="Itemlista"> <strong> Saldo: </strong> R$ {cel.saldoCampanha},00 </li>
                                <li className="Itemlista"> <strong> Status: </strong>
                                    {cel.tipoDeCampanha === listaTiposDeCampanha[2] || cel.tipoDeCampanha === listaTiposDeCampanha[3]
                                        || cel.tipoDeCampanha === listaTiposDeCampanha[4] ?
                                        <span className={`${!cel.codigoActionpay ? "emIntegracaoMobile" : cel.status === "ativa" ? "ativoMobile" : "desativadoMobile"}`}> {!cel.codigoActionpay ? "Em integração" : cel.status} </span>
                                        :
                                        <span className={`${cel.status === "ativa" ? "ativoMobile" : "desativadoMobile"}`}> {cel.status} </span>
                                    }

                                </li>
                                <li className="Itemlista"> <strong> Criado em: </strong> {cel.inicioInscricao.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </li>
                                <li className="Itemlista">
                                    <div className="divAcoes">
                                        <strong> Ações: </strong>
                                        <AttachMoneyIcon className={styles.IconeSaldo} onClick={() => abreModalSaldo(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} />
                                        {!cel.jaAtivadaAntes && <Link to={`/anunciante/editar-campanha?id=${cel.id}`}> <EditIcon className={styles.IconeEditar} /> </Link>}
                                        {cel.tipoDeCampanha === listaTiposDeCampanha[2] || cel.tipoDeCampanha === listaTiposDeCampanha[3]
                                            || cel.tipoDeCampanha === listaTiposDeCampanha[4] ?
                                            <> {cel.codigoActionpay && cel.status === "ativa" && <CancelOutlinedIcon onClick={() => abreModalDesativar(cel.id)} className={styles.IconeFinalizar} />}
                                                {cel.codigoActionpay && cel.status === "desativada" && <CheckCircleOutlineOutlinedIcon onClick={() => abreModalAtivar(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} className={styles.IconeAtivar} />}
                                                <Link to={`/anunciante/estatisticas-da-campanha?id=${cel.codigoActionpay}}&tp=anun`}> <EqualizerOutlinedIcon className={styles.IconeEstatistica} /> </Link>
                                                {/* <Tooltip title="Adicionar novos objetivos." arrow classes={{ tooltip: styles.tooltip }}>
                                                    <Link to={`/anunciante/adicionar-novos-objetivos?id=${cel.id}`}> <QueueIcon className={styles.IconeNovosObjetivos} /> </Link>
                                                </Tooltip> */}
                                            </>

                                            :
                                            <>
                                                {cel.status === "ativa" && <CancelOutlinedIcon onClick={() => abreModalDesativar(cel.id)} className={styles.IconeFinalizar} />}
                                                {cel.status === "desativada" && <CheckCircleOutlineOutlinedIcon onClick={() => abreModalAtivar(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} className={styles.IconeAtivar} />}
                                                {/* <Link to={`/anunciante/estatisticas-de-campanha?id=${cel.id}`}> <EqualizerOutlinedIcon className={styles.IconeEstatistica} /> </Link> */}
                                            </>
                                        }

                                        {/* {cel.codigoActionpay && cel.status === "ativa" && <CancelOutlinedIcon onClick={() => abreModalDesativar(cel.id)} className={styles.IconeFinalizar} />}
                                        {cel.codigoActionpay && cel.status === "desativada" && <CheckCircleOutlineOutlinedIcon onClick={() => abreModalAtivar(cel.id, cel.saldoCampanha, cel.nomeDaCampanha)} className={styles.IconeAtivar} />}
                                        <Link to={`/anunciante/estatisticas-de-campanha?id=${cel.id}`}> <EqualizerOutlinedIcon className={styles.IconeEstatistica} /> </Link> */}
                                    </div>
                                </li>
                                <div>
                                    <Modal
                                        open={open}
                                        onClose={fechaModal}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={styleModal}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Deseja desativar campanha?
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                Tem certeza que deseja desativar esta campanha ? Ela irá desaparecer
                                                da lista dos influenciadores.

                                                <div className={styles.divBotoesModal}>
                                                    <button className="btn btn-danger" onClick={desativarCampanha}> Sim </button>
                                                    <button className="btn btn-primary" onClick={fechaModal}> Não </button>
                                                </div>
                                            </Typography>
                                        </Box>
                                    </Modal>
                                </div>
                                <div>
                                    <Modal
                                        open={openn}
                                        onClose={fechaModall}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={styleModal}>
                                            {campanhaComSaldo ? <> <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Deseja ativar a campanha?
                                            </Typography>
                                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                    Ao ativar a campanha, ela irá aparecer para todos os outros
                                                    influenciadores, novamente! E você não poderá editar essa campanha.

                                                    <div className={styles.divBotoesModal}>
                                                        <button className="btn btn-success" onClick={ativarCampanha}> Sim </button>
                                                        <button className="btn btn-primary" onClick={fechaModall}> Não </button>
                                                    </div>
                                                </Typography> </> : <> <Typography id="modal-modal-title" variant="h6" component="h2">
                                                    Você não possui saldo nessa campanha!
                                                </Typography>
                                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                    Para ativar a campanha, você precisa adicionar saldo.
                                                    Deseja adicionar saldo nessa campanha?

                                                    <div className={styles.divBotoesModal}>
                                                        <button className="btn btn-success" onClick={trocarModal}> Sim </button>
                                                        <button className="btn btn-primary" onClick={fechaModall}> Não </button>
                                                    </div>
                                                </Typography> </>}
                                        </Box>
                                    </Modal>
                                </div>
                                <div>
                                    <Modal
                                        open={openSaldo}
                                        onClose={fechaModalSaldo}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={styleModal}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Seu saldo é de: R$ {saldoCarteira},00
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                Adicone orçamento na campanha {nomeDaCampanha}, esse valor será debitado
                                                de seu saldo.

                                                <input value={mascaraDinheiro(orcamento)} onChange={(e) => setOrcamento(e.target.value)} className={styles.InputModal} />
                                                {alertaSaldo && <p className={styles.AlertaSaldo}> Saldo insuficiente para esse orçamento! </p>}
                                                {alertaSaldoInferior && <p className={styles.AlertaSaldo}> Orçamento mínimo de R$100,00 </p>}

                                                <div className={styles.divBotoesModal}>
                                                    <button className="btn btn-success" onClick={adicionarOrcamento}> Adicionar </button>
                                                    <button className="btn btn-secondary" onClick={fechaModalSaldo}> Voltar </button>
                                                </div>
                                            </Typography>
                                        </Box>
                                    </Modal>
                                </div>
                            </ul>
                        ))}
                    </div>}
                </>

            </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    )
}