import styles from "./DetalhesCampanha.module.css"
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import WrapTextOutlinedIcon from '@mui/icons-material/WrapTextOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CategoryIcon from '@mui/icons-material/Category';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { addDoc, collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../../../db/banco";
import ICriarCampanha from "../../../../types/ICriarCampanha";
import OneKkIcon from '@mui/icons-material/OneKk';
import DescriptionIcon from '@mui/icons-material/Description';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import IChatPropostas from "../../../../types/ICharPropostas";
import autenticaStore from "../../../../stores/autentica.store";
import IInfluenciador from "../../../../types/IInfluenciador";
import axios from "axios";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import aceitoStore from "../../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FlagIcon from '@mui/icons-material/Flag';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { listaTiposDeCampanha } from "../../../../utils/listas";
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ILinkGeradoISM from "../../../../types/ILinkGeradoISM";
import FormatValues from "./FormatValues";

export default function DetalhesCampanha() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    const navigate = useNavigate();
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    // recebe a campanha
    const [campanha, setCampanha] = useState<ICriarCampanha[]>([]);
    // Recebe o link caso existe
    const [linkGeradoISMExistente, setLinkGeradoISMExistente] = useState<ILinkGeradoISM[]>([]);
    // Verifica se ja tem uma proposta e controla os botões
    const [jaTem, setJaTem] = useState(true);
    const [atualiza, setAtualiza] = useState(false);
    // id do chat
    const [idChat, setIdChat] = useState([""])
    // dados para pegar depois
    const [nomeCampanha, setNomeCampanha] = useState("");
    const [nomeInfluenciador, setNomeInfluenciador] = useState("");
    const [criadoEm, setCriadoEm] = useState("");
    const [emailDoAnunciante, setEmailDoAnunciante] = useState("");
    const [nomeDoAnunciante, setNomeDoAnunciante] = useState("");
    const [idDoInfluenciador, SetIdDoInfluenciador] = useState("");
    const [tipoDePessoa, setTipoDePessoa] = useState("");
    const [tipoCampanha, setTipoCampanha] = useState("");
    const [saldoCampanha, setSaldoCampanha] = useState(0);

    // imagem
    const [downloadImagem, setDownloadImagem] = useState("");
    const [idImagem, setIdImagem] = useState("");
    // Gerar link
    const [codigoActionpay, setCodigoActionpay] = useState("");
    const [linkCruActionpay, setLinkCruActionpay] = useState("");
    const [cleanURL, setCleanURL] = useState("");
    const [idActionpayInfluenciador, setIdActionpayInfluenciador] = useState(0);
    // Verifica se o influenciador já está nessa campanha, para mostrar o link
    const [influenciadorNaCampanha, setInfluenciadorNaCampanha] = useState(0);

    // Pega o link gerado
    useEffect(() => {
        buscarLink();
    }, [codigoActionpay])


    // banco de dados
    const useCollectionRef = collection(db, "criarCampanha");
    // puxar dados campanha
    useEffect(() => {
        const obterCampanha = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha)

            const campanhaEncontrada: ICriarCampanha[] = todasCampanhas.filter(dis => dis.id === parametro);

            setCampanha(campanhaEncontrada);


            campanhaEncontrada.map(tudo => {
                setNomeCampanha(tudo.nomeDaCampanha);
                setCriadoEm(tudo.inicioInscricao);
                setEmailDoAnunciante(tudo.anuncianteEmail);
                setNomeDoAnunciante(tudo.nomeAnunciante);
                setIdImagem(tudo.idStorage);
                setTipoCampanha(tudo.tipoDeCampanha);
                setCodigoActionpay(tudo.codigoActionpay);
                setSaldoCampanha(tudo.saldoCampanha);
            });
        }
        obterCampanha();
    }, [])

    function decodeHTML(html: any) {
        const temp = document.createElement('div');
        temp.innerHTML = html;
        return temp.innerText || temp.textContent || '';
    }
    // banco de dados
    const useCollectionReff = collection(db, "chatPropostas");
    // puxar dados, ve se existe chat de proposta nessa campanha
    useEffect(() => {
        const verificaProposta = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todosChatsPropostas: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IChatPropostas);
            const jaTemProposta: IChatPropostas[] = todosChatsPropostas.filter(chat => chat.idCampanha === parametro);
            const jaTemPropostaEmail = jaTemProposta.filter(ja => ja.emailInfluenciador === email)




            const pegaId = jaTemPropostaEmail.map(idd => idd.id);

            setIdChat(pegaId)


            if (jaTemPropostaEmail.length === 0) setJaTem(true);
            else setJaTem(false);

            const estaNessa = jaTemProposta.filter(esseInfluenciador => esseInfluenciador.emailInfluenciador === email);
            setInfluenciadorNaCampanha(estaNessa.length);

        }
        verificaProposta();
    }, [atualiza]);

    // banco de dados
    const useCollectionRefff = collection(db, "influenciador");
    // puxar dados campanha
    useEffect(() => {
        const obterInfluenciador = async () => {
            const dataBD = await getDocs(useCollectionRefff);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
            const influenciadorAchado: IInfluenciador[] = todosInfluenciadores.filter(influ => influ.email === email);

            influenciadorAchado.map(influenciador => {
                setNomeInfluenciador(influenciador.nomeCompleto);
                SetIdDoInfluenciador(influenciador.id);
                setTipoDePessoa(influenciador.tipoDePessoa);
                setIdActionpayInfluenciador(influenciador.codigoActionpayInfluenciador);
            });
        }
        obterInfluenciador();
    }, [])

    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: emailDoAnunciante,
                    assunto: "Tem influencer interessado na sua campanha!!",
                    mensagem: `Oi, ${nomeDoAnunciante}! A sua campanha ${nomeCampanha} recebeu uma nova proposta :)\n` +
                        `A proposta foi feita pelo ${nomeInfluenciador}. Você pode tirar dúvidas e negociar através do\n` +
                        "chat\n\n" +

                        "Avaliar proposta: influenciesuamarca.com.br/anunciante/influenciadores-em-analise\n\n" +

                        "Conte com a gente para influenciar sempre mais.\n" +
                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    // banco de dados
    const useCollectionRefNotificacao = collection(db, "notificacao");

    async function criarNovaNotificacao() {
        const notificacao = await addDoc(useCollectionRefNotificacao, {
            mensagem: `O influenciador ${nomeInfluenciador}, está interessado em sua campanha: ${nomeCampanha}!`,
            visto: false,
            tipoDoUsuarioQueVaiReceberNotificacao: "anunciante",
            emailDeQuemVaiReceberNotificacao: emailDoAnunciante,
            data: new Date().toLocaleDateString(),
            link: "https://influenciesuamarca.com.br/anunciante/influenciadores-em-analise"
        })
    }

    async function criarNovoChat() {
        enviarEmail();
        criarNovaNotificacao();

        const chatProposta = await addDoc(useCollectionReff, {
            idCampanha: parametro,
            emailAnunciante: emailDoAnunciante,
            conversaLadoInfluenciador: ["Oi"],
            conversaAnunciante: ["Oi"],
            nomeCampanha,
            nomeInfluenciador,
            nomeDoAnunciante,
            criadoEm,
            statusProposta: "espera",
            emailInfluenciador: email,
            valorCombinado: 0,
            idInfluenciador: idDoInfluenciador,
            tipoPessoa: tipoDePessoa,
            tipoCampanha,
            saldoCampanha,
            linksTrabalho: [""],
            codigoActionpay
        })

        setAtualiza(!atualiza);
    }

    function irParaPropostas() {
        navigate(`/influenciador/chat-proposta?id=${idChat}&tp=influ`);
    }



    // Para mostrar a imagem
    useEffect(() => {

        if (emailDoAnunciante && idImagem) {
            const storage = getStorage();
            const listRef = ref(storage, `gs://ism-teste.appspot.com/anunciante/${emailDoAnunciante}/campanhas/${idImagem}`);
            const listaCaminhosPdfs: any = [];

            const fetchData = async () => {
                try {
                    const res = await listAll(listRef);
                    res.items.forEach((itemRef => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    }));

                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.open('GET', url);
                        xhr.send();
                        setDownloadImagem(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [emailDoAnunciante, idImagem]);

    // Gerar link

    const fetchDataBuscarLinks = async () => {
        const formatoAPI = "json";
        try {
            // Chamada para a função Firebase Functions com os parâmetros
            const response = await axios.get(`https://us-central1-ism-teste.cloudfunctions.net/buscarLinks?offer=${codigoActionpay}&format=${formatoAPI}`);
            // Armazenar os dados na variável de estado


            setLinkCruActionpay(response.data.result.links[0].url);
            setCleanURL(response.data.result.links[0].cleanUrl);

            if (!response.data.error) {


            } else {
                alert("Código errado, ou campanha não adicionada nos favoritos")

            }

        } catch (error) {
            console.error('Erro ao buscar dados da Actionpay:', error);
        }
    };

    async function buscarLink() {
        try {
            await fetchDataBuscarLinks(); // Espera até que fetchDataFromActionpay seja concluída            
        } catch (error) {
            console.error('Erro ao buscar dados da Actionpay:', error);
        }
    }

    // Botão copiar
    function copiarCodigo() {
        navigator.clipboard.writeText(`ism.app.br/${codigoActionpay}/${idActionpayInfluenciador}`)
    }

    // Salva no banco de dados, caso seja gerado o link pela primeira vez
    // banco de dados
    const useCollectionRefLinkISM = collection(db, "linkGeradoISM");
    useEffect(() => {
        const verificaSeJaExisteLinkGerado = () => {

        }
        async function criarLinkISM() {
            const linkGerado = await addDoc(useCollectionRefLinkISM, {
                linkISM: `ism.app.br/${codigoActionpay}/${idActionpayInfluenciador}`,
                linkActionpay: "",
                emailInfluenciador: email,
                emailAnunciante: emailDoAnunciante,
                codigoDaCampanha: codigoActionpay,
                codigoDoInfluenciador: idActionpayInfluenciador
            })
        }
        if (emailDoAnunciante && idActionpayInfluenciador && codigoActionpay) {
            const obterLinkGeradoISM = async () => {
                const dataBD = await getDocs(useCollectionRefLinkISM);
                const todosLinksGeradosISM: ILinkGeradoISM[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ILinkGeradoISM);
                const linkGeradoISMEncontrado: ILinkGeradoISM[] = todosLinksGeradosISM.filter(influ =>
                    influ.linkISM === `ism.app.br/${codigoActionpay}/${idActionpayInfluenciador}`
                );

                if (linkGeradoISMEncontrado.length > 0) return true;
                else return false;

            }
            obterLinkGeradoISM();

            obterLinkGeradoISM().then(aux => {

                if (!aux) criarLinkISM();
                else return;
            });

        } else {
            // console.log("falta parametross");

        }
        verificaSeJaExisteLinkGerado();

    }, [email, emailDoAnunciante, idActionpayInfluenciador, codigoActionpay])

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.DetalhesCampanha}>
                <h2> Detalhes da campanha </h2>

                {downloadImagem ? <img src={downloadImagem} alt="foto de perfil" /> : <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>}
                <form>
                    <h3> Sobre a campanha </h3>
                    {campanha.map(camp => (
                        <>
                            {camp.tipoDeCampanha === listaTiposDeCampanha[2] || camp.tipoDeCampanha === listaTiposDeCampanha[3]
                                || camp.tipoDeCampanha === listaTiposDeCampanha[4] ? <> {influenciadorNaCampanha > 0 ? <div>
                                    <LinkIcon fontSize="large" />
                                    <label> <strong> Link da campanha: </strong> {codigoActionpay ?
                                        `ism.app.br/${codigoActionpay}/${idActionpayInfluenciador}` :
                                        "Código será gerado em breve"}
                                        <ContentCopyIcon className={styles.BotaoCopiar} onClick={copiarCodigo} />
                                    </label>
                                </div> : ""} </> : ""}

                            <div>
                                <DriveFileRenameOutlineIcon fontSize="large" />
                                <label> <strong> Nome: </strong> {camp.nomeDaCampanha} </label>
                            </div>

                            <div>
                                <WrapTextOutlinedIcon fontSize="large" />
                                <label> <strong> Tipo de campanha: </strong> {camp.tipoDeCampanha} </label>
                            </div>

                            {camp.tipoDeCampanha === listaTiposDeCampanha[2] || camp.tipoDeCampanha === listaTiposDeCampanha[3]
                                || camp.tipoDeCampanha === listaTiposDeCampanha[4] ? <>
                                {/* <div>
                                    <ChecklistIcon fontSize="large" />
                                    <label> <strong> Tipo de performance: </strong> {camp.tipoPerformance} </label>
                                </div> */}

                                {/* <div>
                                    <AttachMoneyIcon fontSize="large" />
                                    <label> <strong> {camp.valorFixo ? "Valor combinado" : "Porcentagem combinada"} </strong> {camp.valorFixo ? `R$${camp.valorFixo},00` : `${camp.valorVariavel}%`} </label>
                                </div> */}

                                {camp.vendaConfirmada && <> <div>
                                    <FlagIcon fontSize="large" />
                                    <label> <strong> Objetivo: </strong> {camp.vendaConfirmada && "Venda confirmada"}: <FormatValues valor={camp.vendaConfirmada}/> </label>
                                </div></>}

                                {camp.cadastro && <> <div>
                                    <FlagIcon fontSize="large" />
                                    <label> <strong> Objetivo: </strong> {camp.cadastro && "Cadastro"}: {camp.cadastro},00 </label>
                                </div></>}

                                {camp.cadastro && <> <div>
                                    <FlagIcon fontSize="large" />
                                    <label> <strong> Objetivo: </strong> {camp.inscricao && "Inscrição"}: {camp.inscricao},00 </label>
                                </div></>}

                                {camp.cadastro && <> <div>
                                    <FlagIcon fontSize="large" />
                                    <label> <strong> Objetivo: </strong> {camp.primeiroDeposito && "Primeiro depósito"}: {camp.primeiroDeposito},00 </label>
                                </div></>}

                                {camp.cadastro && <> <div>
                                    <FlagIcon fontSize="large" />
                                    <label> <strong> Objetivo: </strong> {camp.instalacao && "Instalação"}: {camp.instalacao},00 </label>
                                </div></>}

                            </> : ""}


                            <div>
                                <EventNoteIcon fontSize="large" />
                                <label> <strong> Inscrição aberta: </strong> {camp.inicioInscricao.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </label>
                            </div>

                            <div>
                                <EventBusyOutlinedIcon fontSize="large" />
                                <label> <strong> Inscrição fechada: </strong> {camp.fimInscricao.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </label>
                            </div>

                            <div>
                                <TypeSpecimenIcon fontSize="large" />
                                <label> <strong> Tipo de material: </strong> {camp.tipoDeMaterial} </label>
                            </div>

                            <div>
                                <CategoryIcon fontSize="large" />
                                <label> <strong> Nichos: </strong> {camp.nichos} </label>
                            </div>

                            <div>
                                <InsertLinkIcon fontSize="large" />
                                <label> <strong> Link: </strong> {camp.link} </label>
                            </div>

                            {camp.tipoDeCampanha === listaTiposDeCampanha[1] || camp.tipoDeCampanha === listaTiposDeCampanha[3] ?
                                <div>
                                    <InsertLinkIcon fontSize="large" />
                                    <label> <strong> Item da permuta: </strong> {camp.textoPermuta} </label>
                                </div> : ""
                            }

                            <div>
                                <DescriptionIcon fontSize="large" />
                                <label > <strong> Descrição: </strong> {decodeHTML(camp.descreva)} </label>
                            </div>
                        </>
                    ))}
                </form>

                <form>
                    <h3> Influenciador desejado </h3>
                    {campanha.map(camp => (
                        <>
                            <div>
                                <AccountBoxIcon fontSize="large" />
                                <label > <strong> Gênero: </strong> {camp.genero} </label>
                            </div>

                            <div>
                                <AccountBoxIcon fontSize="large" />
                                <label><strong> Aceita outro gênero? </strong>{camp.switchGenero ? 'Sim' : 'Não'}</label>
                            </div>

                            <div>
                                <OneKkIcon fontSize="large" />
                                <label > <strong> Quantidade de seguidores: </strong> {camp.qtdSeguidores} </label>
                            </div>

                            <div>
                                <OneKkIcon fontSize="large" />
                                <label><strong> Aceita fora dessa quantidade de seguidores? </strong>{camp.switchQtdSeguidores ? 'Sim' : 'Não'}</label>
                            </div>

                            <div>
                                <CategoryIcon fontSize="large" />
                                <label > <strong> Nichos: </strong> {camp.categorias.map((categoria, index) => (
                                    <React.Fragment key={index}>
                                        {categoria}
                                        {index !== camp.categorias.length - 1 && ', '}
                                    </React.Fragment>
                                ))} </label>
                            </div>

                            <div>
                                <CategoryIcon fontSize="large" />
                                <label><strong> Aceita fora desse(s) nicho(s)? </strong>{camp.switchCategoria ? 'Sim' : 'Não'}</label>
                            </div>

                            <div>
                                <PermContactCalendarIcon fontSize="large" />
                                <label > <strong> Faixa de idade: </strong> de {camp.idadeInicio} a {camp.idadeFim} </label>
                            </div>

                            <div>
                                <PermContactCalendarIcon fontSize="large" />
                                <label><strong> Aceita fora dessa faixa de idade? </strong>{camp.switchIdade ? 'Sim' : 'Não'}</label>
                            </div>
                        </>
                    ))}
                </form>

                <div className={styles.Botoes}>
                    <h3> Ações </h3>
                    {jaTem ? <button className="btn btn-primary" onClick={criarNovoChat}>
                        Solicitar acesso </button> :
                        <button className="btn btn-primary" onClick={irParaPropostas}> Ver chat </button>}

                </div>

            </section> : <UsuarioNaoAceitoInfluenciador />}
        </>
    );
}