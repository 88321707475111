import { useState } from "react";
import CampoEscolha from "../../../../components/CampoEscolha";
import { listaTipoDePerformance } from "../../../../utils/tipoDePerformance";
import ChecklistIcon from '@mui/icons-material/Checklist';
import CampoTexto from "../../../../components/CampoTexto";
import { mascaraDinheiro } from "../../../../utils/mascaras";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import styles from "./Aims.module.css";

interface AimsProps {
    title: string;
    control: (value: string) => void;
    value: string;    
}

export default function Aims({ title, control, value}: AimsProps
) {
    const [typeConfirmedSell, setTypeConfirmedSell] = useState("");
    return (
        <div className={styles.Aims}>
            <p>{title}</p>
            {title !== "Venda confirmada" ? (
                // Demais metas
                <>                
                    <CampoTexto
                        titulo='Valor'
                        valor={mascaraDinheiro(value)}
                        controle={control}
                        icone={<AttachMoneyIcon fontSize='large' />}
                        placeholder="R$ 100"
                    />

                </>
            ) : (
                // Venda confirmada
                <>
                    <CampoEscolha
                        lista={listaTipoDePerformance}
                        titulo="Selecione"
                        controle={setTypeConfirmedSell} // Passa a função diretamente
                        icone={<ChecklistIcon fontSize="large" />}
                        valorPadrao={typeConfirmedSell || "Selecione entre os tipos"}
                    />

                    {typeConfirmedSell === "Valor variável" &&
                        <CampoTexto
                            titulo='Porcentagem'
                            valor={value}
                            controle={control}
                            icone={<AttachMoneyIcon fontSize='large' />}
                            placeholder="10%"
                        />
                    }
    
                    {typeConfirmedSell === "Valor fixo" && <CampoTexto
                        titulo='Valor'
                        valor={mascaraDinheiro(value)}
                        controle={control}
                        icone={<AttachMoneyIcon fontSize='large' />}
                        placeholder="R$ 100"
                    />}

                </>

            )}
        </div>
    );
}

