import React, { useEffect, useState } from 'react';
import { ITransactionID } from '../../../../types/ITransactionID';
import { formatarData } from '../functions';
import FormatarDinheiro from '../FormatarDinheiro';
import { obterTodosInfluenciadores } from '../../../../utils/db';
import IInfluenciador from '../../../../types/IInfluenciador';
import styles from "./TabelaTransacao.module.css"
import { buscarObjetivos } from '../../../../utils/api';
import { Action } from '../../../../interfaces/DadosEstatisticasProps';
import { log } from 'node:console';

interface TabelaTransacaoProps {
    transactionIDPaginados: ITransactionID[];
    tipoUsuario: string;
    idCampanhaActionpay: string
}

const TabelaTransacao: React.FC<TabelaTransacaoProps> = ({ transactionIDPaginados, tipoUsuario, idCampanhaActionpay }) => {
    // recebe o influenciador
    const [Influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    // Carrega dados dos influenciadores
    useEffect(() => {
        const carregarInfluenciadores = async () => {
            const todosInfluenciadores = await obterTodosInfluenciadores();
            setInfluenciadores(todosInfluenciadores)
        };
        carregarInfluenciadores();
    }, []);

    const [actions, setActions] = useState<Action[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchDataObjetivos = async () => {
            alert(idCampanhaActionpay)
            try {
                const response = await buscarObjetivos(idCampanhaActionpay ? idCampanhaActionpay : "");
                if (response) {
                    
                    const actions = response.result?.actions || [];
                    setActions(actions);
                    console.log(actions);
                    
                    
                    
                } else {
                    setError('Erro ao carregar os dados.');
                }
            } catch (error) {
                setError('Erro na chamada da API.');
            } finally {
                setLoading(false);  // Atualiza o estado de loading
            }
        };

        fetchDataObjetivos();
    }, [idCampanhaActionpay])

    return (
        <div className={styles.TabelaTransacao}>
            <table className="table table-light table-striped">
                <thead>
                    <tr>
                        <th className="text-center">ID da transação</th>
                        <th className="text-center">Data e hora</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Valor da transação</th>
                        <th className="text-center">Comissão</th>
                        {tipoUsuario !== "influ" && <th className="text-center">Influenciador</th>}
                        <th className="text-center"> Objetivo </th>
                    </tr>
                </thead>
                <tbody>
                    {transactionIDPaginados.map((action, index) => (
                        <tr key={action.uniqueid} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <td data-label="ID da transação">{action.apid}</td>
                            <td data-label="Data e hora">{formatarData(action.date)}</td>
                            <td data-label="Status"
                                className={`${action.status.id === 400 ? 'desativadoMobile' : action.status.id === 300
                                    ? 'processando'
                                    : 'ativoMobile'}`}
                            >
                                {action.status.id === 400
                                    ? 'Rejeitada'
                                    : action.status.id === 300
                                        ? 'Em espera'
                                        : 'Aprovada'}
                            </td>
                            <td data-label="Valor da transação" className="text-center">
                                <FormatarDinheiro price={action.price} currency={action.priceCurrency} />
                            </td>
                            <td data-label="Comissão" className="text-center">
                                <FormatarDinheiro price={action.payment} currency={action.priceCurrency} />
                            </td>
                            {tipoUsuario !== "influ" && (
                                <td data-label="Influenciador" className="text-center">
                                    {(() => {
                                        const influenciador = Influenciadores.find(
                                            influ => influ.codigoActionpayInfluenciador === Number(action.subId1)
                                        );
                                        return influenciador ? (
                                            <span>{influenciador.nomeCompleto}</span>
                                        ) : 'Nome não encontrado';
                                    })()}
                                </td>
                            )}
                            <td data-label="Objetivo" className="text-center"> {action.aim} </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
};

export default TabelaTransacao;
