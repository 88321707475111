import { Link, useLocation } from "react-router-dom";
import styles from "./LinksInfluencers.module.css";
import { obterTodasCampanhas, obterTodosInfluenciadores, obterTodosLinksGerados } from "../../../../../utils/db";
import { useState, useEffect } from "react";
import ILinkGeradoISM from "../../../../../types/ILinkGeradoISM";
import { Pagination, Stack, Tooltip, useMediaQuery } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { action } from "mobx";
import IInfluenciador from "../../../../../types/IInfluenciador";

export default function LinksInfluencers() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id");
    const [links, setLinks] = useState<ILinkGeradoISM[]>([]);
    const [idCampanhaActionpay, setIdCampanhaActionpay] = useState("");
    const isMobile = useMediaQuery('(max-width:500px)');

    // Carrega dados do link
    useEffect(() => {
        const carregarCampanhas = async () => {
            const todasCampanhas = await obterTodasCampanhas();
            const filtrarSomenteDessaCampanha = todasCampanhas.filter(somenteDessaCampanha => somenteDessaCampanha.id === parametro);
            filtrarSomenteDessaCampanha.map(pegarIdActionpay => {
                setIdCampanhaActionpay(pegarIdActionpay.codigoActionpay)
            })
        };
        carregarCampanhas();
    }, [parametro]);

    // Carrega dados do link
    useEffect(() => {
        const carregarLinksGerados = async () => {
            const todosLinks = await obterTodosLinksGerados();
            const filtrarSomenteDessaCampanha = todosLinks.filter(somenteDessaCampanha => somenteDessaCampanha.codigoDaCampanha === idCampanhaActionpay);
            setLinks(filtrarSomenteDessaCampanha);
            console.log(links);

        };
        if (idCampanhaActionpay !== "") carregarLinksGerados();
    }, [idCampanhaActionpay]);

    const [copiadoIndex, setCopiadoIndex] = useState<number | null>(null);

    const copiarCodigo = (codigo: string, index: number) => {
        navigator.clipboard.writeText(codigo); // Copia o texto para o clipboard
        setCopiadoIndex(index); // Define a linha clicada como "Copiada!"
        setTimeout(() => setCopiadoIndex(null), 5000); // Reseta o estado após 3 segundos
    };

    // Paginação
    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };

    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const linksPaginados = links.slice(startIndex, endIndex);

    // recebe o influenciador
    const [Influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    // Carrega dados dos influenciadores
    useEffect(() => {
        const carregarInfluenciadores = async () => {
            const todosInfluenciadores = await obterTodosInfluenciadores();
            setInfluenciadores(todosInfluenciadores)
        };
        carregarInfluenciadores();
    }, []);

    return (
        <section className={styles.LinksInfluencers}>
            <h2> Links de divulgação da campanha </h2>

            <Stack spacing={2}>
                {isMobile ? (
                    // Renderizar como cards
                    <div>
                        {linksPaginados.map((linksGerados, index) => (
                            <div
                                key={linksGerados.id}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                                    padding: '10px',
                                    marginBottom: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <p>
                                    <strong className={styles.StrongCard}> E-mail: </strong>{' '}
                                    <Link to={`/moderador/ver-perfil-do-influenciador?id=${linksGerados.emailInfluenciador}`} className={styles.LinkCard}>
                                        {linksGerados.emailInfluenciador}
                                    </Link>
                                </p>
                                <p>
                                    <strong className={styles.StrongCard}> Nome: </strong>{' '}
                                    {(() => {
                                        const influenciador = Influenciadores.find(
                                            influ => influ.codigoActionpayInfluenciador === Number(linksGerados.codigoDoInfluenciador)
                                        );
                                        return influenciador ? (
                                            <Link to={`/moderador/ver-perfil-do-influenciador?id=${linksGerados.emailInfluenciador}`} className={styles.LinkCard}>
                                                {influenciador.nomeCompleto}
                                            </Link>

                                        ) : 'Nome não encontrado';
                                    })()}
                                </p>
                                <p>
                                    <strong>Link:</strong> {linksGerados.linkISM}{' '}
                                    <Tooltip
                                        title={copiadoIndex === index ? 'Copiado!' : 'Copiar link'}
                                        arrow
                                    >
                                        <ContentCopyIcon
                                            style={{ cursor: 'pointer' }}
                                            className={styles.IconeCard}
                                            onClick={() => copiarCodigo(linksGerados.linkISM, index)}
                                        />
                                    </Tooltip>
                                </p>
                            </div>
                        ))}
                    </div>
                ) : (
                    // Renderizar como tabela
                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white">
                                    E-mail
                                </th>
                                <th scope="col" className="text-white">
                                    Nome
                                </th>
                                <th scope="col" className="text-white">
                                    Link
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {linksPaginados.map((linksGerados, index) => (
                                <tr
                                    key={linksGerados.id}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                                    }}
                                >
                                    <td>
                                        <Link to={`/moderador/ver-perfil-do-influenciador?id=${linksGerados.emailInfluenciador}`}>
                                            {linksGerados.emailInfluenciador}
                                        </Link>
                                    </td>
                                    <td data-label="Influenciador" className="text-center">
                                        {(() => {
                                            const influenciador = Influenciadores.find(
                                                influ => influ.codigoActionpayInfluenciador === Number(linksGerados.codigoDoInfluenciador)
                                            );
                                            return influenciador ? (
                                                <Link to={`/moderador/ver-perfil-do-influenciador?id=${linksGerados.emailInfluenciador}`}>
                                                    <span>{influenciador.nomeCompleto}</span>
                                                </Link>

                                            ) : 'Nome não encontrado';
                                        })()}
                                    </td>
                                    <td>
                                        {linksGerados.linkISM}{' '}
                                        <Tooltip
                                            title={copiadoIndex === index ? 'Copiado!' : 'Copiar link'}
                                            arrow
                                        >
                                            <ContentCopyIcon
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => copiarCodigo(linksGerados.linkISM, index)}
                                            />
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </Stack>

            <Pagination
                count={Math.ceil(links.length / linhasPorPagina)}
                page={pagina}
                onChange={handleChangePagina}
            />
        </section>
    )
}