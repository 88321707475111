import { useLocation, useNavigate } from "react-router-dom";
import styles from "./LeituraInfluenciador.module.css";
import { useEffect, useState } from "react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import EmailIcon from '@mui/icons-material/Email';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EightKIcon from '@mui/icons-material/EightK';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PortraitIcon from '@mui/icons-material/Portrait';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import pessoa from "../../../../../../Image/usuario.png";
import InfoIcon from '@mui/icons-material/Info';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import DirectionsIcon from '@mui/icons-material/Directions';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PinIcon from '@mui/icons-material/Pin';
import MoneyIcon from '@mui/icons-material/Money';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import axios from "axios";
import { db } from "../../../../../../db/banco";
import guardaPaginaAnterior from "../../../../../../stores/paginaAnterior";
import IInfluenciador from "../../../../../../types/IInfluenciador";


export default function VerPerfilInfluenciadorPorEmail() {
    const navitate = useNavigate();
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id");
    // pagina
    const paginaParametro = params.get("pg");
    const isRecusado = params.get("tp");
    // recebe e controla o influenciador
    const [influenciador, setInfluenciador] = useState<IInfluenciador[]>([]);
    const [email, setEmail] = useState("");
    // puxar foto de perfil
    const [foto, setFoto] = useState("");
    const [downloadFoto, setDownloadFoto] = useState("");
    // Midia kit
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");
    const [statusEmail, setSstatusEmail] = useState(false);
    // atualizar tabela
    const [atualiza, setAtualiza] = useState(false);
    // salva a Página

    useEffect(() => {
        if (paginaParametro) guardaPaginaAnterior.salvarPagina(paginaParametro, true);
    }, [paginaParametro]);

    // banco de dados
    const useCollectionRef = collection(db, "influenciador");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador)
            const filtrado: IInfluenciador[] = todosInfluenciadores.filter(filtrado => filtrado.email === parametro);
            setInfluenciador(filtrado);
            console.log("influenciador:", filtrado);
            filtrado.map(fil => {
                setEmail(fil.email);
                setSstatusEmail(fil.emailVerificado);
            })
        }
        obterAnunciantes();
    }, [atualiza])

    // Lista caminho, edita e fazer download da imagem
    useEffect(() => {
        // Verifica se o email está carregado antes de buscar a imagem
        if (email) {
            const fetchData = async () => {
                try {
                    const storage = getStorage();
                    const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/fotoDoPerfil`);
                    const listaCaminhosPdfs: any = [];

                    const res = await listAll(listRef);
                    res.items.forEach((itemRef) => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    });


                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        setDownloadFoto(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [email]);

    // Lista caminho, edita e fazer download do midia kit
    useEffect(() => {
        // Verifica se o email está carregado antes de buscar a imagem
        if (email) {
            const fetchData = async () => {
                try {
                    const storage = getStorage();
                    const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/midiakit`);
                    const listaCaminhosPdfs: any = [];

                    const res = await listAll(listRef);
                    res.items.forEach((itemRef) => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    });


                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        setDownloadMidiaKit(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [email]);

    function baixarPdf() {
        window.open(downloadMidiaKit);
    }

    // Aceitar/recusar influenciador
    async function aceitarInfluenciador(id: string, email: string, nome: string) {
        const influenciador = {
            aceito: "aceito"
        }
        await editarInfluenciador(id, influenciador);
        enviarEmail(email, nome);
        alert("Influenciador aceito");
    };

    async function recusarInfluenciador(id: string, email: string, nome: string) {
        const influenciador = {
            aceito: "recusado",
            jaRecusado: true
        }
        await editarInfluenciador(id, influenciador);
        enviarEmailRecusar(email, nome)
        alert("Influenciador recusado");
    };

    async function editarInfluenciador(id: string, novosDados: any) {
        try {
            if (id) {

                const chamadoDocRef: any = doc(db, "influenciador", id);
                await updateDoc(chamadoDocRef, novosDados);
                if (isRecusado !== "rec") navitate("/moderador/aceitar-usuarios");
                else navitate("/moderador/usuarios-recusados");
                setAtualiza(!atualiza);
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    const enviarEmail = async (email: string, nome: string) => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Ei, influenciador! Temos novidades! ",
                    mensagem: `Oii, ${nome}\n\n` +
                        "Temos uma novidade super legal para te contar!\n\n" +

                        "Você foi aprovado na nossa plataforma!! Incrível, né!?\n\n" +

                        "Agora você pode ficar por dentro de todas as nossas campanhas e participar junto com a\n" +
                        "gente!\n\n" +

                        "Não vemos a hora de trabalharmos com você! 💜\n\n" +

                        "Obrigado por querer influenciar conosco!" +
                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            console.log(error);
        }
    };

    const enviarEmailRecusar = async (email: string, nome: string) => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Informações sobre sua análise de cadastro",
                    mensagem: `Olá, ${nome}\n\n` +

                        "Ficamos muito felizes em saber que deseja participar das nossas campanhas! 🎉\n\n" +

                        "Neste momento, infelizmente, nossos objetivos não deram match e seu cadastro ainda não foi aprovado. 😔\n\n" +

                        "Mas não desanime, não!. \n\n" +

                        "Vamos te explicar um pouquinho sobre como funciona essa análise e temos certeza de que em breve vamos caminhar juntos:\n\n" +

                        "Número de Seguidores: Verificamos se o perfil tem um número significativo de seguidores.\n\n" +

                        "Engajamento: Analisamos o nível de engajamento nas postagens, como curtidas, comentários e compartilhamentos.\n" +
                        "bom influenciador tem um alto nível de interação com seus seguidores.\n\n" +

                        "Qualidade do Conteúdo: Observamos a qualidade das fotos, vídeos e legendas. Conteúdo bem produzido e relevante é um\n" +
                        "indicativo de um influenciador profissional. \n\n" +

                        "Consistência: Verificamos a frequência das postagens. Influenciadores costumam postar regularmente para manter o\n" +
                        "engajamento do público.\n\n" +

                        "Autenticidade: Avaliamos se o conteúdo parece autêntico e genuíno. Influenciadores geralmente têm uma voz autêntica\n" +
                        "que ressoa com seus seguidores.\n\n" +

                        "Nicho: Identificamos se o perfil tem um nicho específico, como moda, beleza, fitness, etc. Influenciadores geralmente se\n" +
                        "especializam em um ou mais nichos.\n\n" +

                        "Então, não é um adeus, é um até logo! 💜\n\n" +

                        "Um abraço do time Influencie Sua Marca."
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <section className={styles.LeituraInfluenciador}>
            <form>
                {downloadFoto ? <img src={downloadFoto} alt="foto de perfil" /> : <img src={pessoa} alt="foto de perfil" />}
                {influenciador.map(inf => (
                    <>
                        <div>
                            <EmailIcon fontSize="large" />
                            <label> <strong> Email: </strong> {inf.email} </label>
                        </div>
                        {statusEmail === true ? <p> Email verificado </p> : <p> Email não verificado </p>}

                        <div>
                            <PersonSearchIcon fontSize="large" />
                            <label> <strong> Tipo de pessoa: </strong> {inf.tipoDePessoa} </label>
                        </div>

                        <div>
                            <InstagramIcon fontSize="large" />
                            <label> <strong> Rede Social Principal: </strong> {inf.redeSocialPrincipal} </label>
                        </div>

                        <div>
                            <AlternateEmailIcon fontSize="large" />
                            <label> <strong> @ principal: </strong> {inf.arrobaPrincipal} </label>
                        </div>

                        <div>
                            <EightKIcon fontSize="large" />
                            <label> <strong> Quantidade de seguidores: </strong> {inf.quantidadeDeSeguidores} </label>
                        </div>

                        <div>
                            <CategoryIcon fontSize="large" />
                            <label> <strong> Categoria: </strong> {inf.categoria} </label>
                        </div>

                        <div>
                            <MapIcon fontSize="large" />
                            <label> <strong> Estado: </strong> {inf.estado} </label>
                        </div>

                        <div>
                            <ForkLeftIcon fontSize="large" />
                            <label> <strong> Cidade: </strong> {inf.cidade} </label>
                        </div>

                        <div>
                            <DateRangeIcon fontSize="large" />
                            <label> <strong> Data de nascimento: </strong> {inf.data.split("-").reverse().join("/")} </label>
                        </div>

                        <div>
                            <PortraitIcon fontSize="large" />
                            <label> <strong> Gênero: </strong> {inf.genero} </label>
                        </div>

                        <div>
                            <SmartphoneIcon fontSize="large" />
                            <label> <strong> Telefone: </strong> {inf.telefone} </label>
                        </div>

                        <div>
                            <InfoIcon fontSize="large" />
                            <label> <strong> Sobre: </strong> {inf.sobre} </label>
                        </div>

                        <div>
                            <NorthEastIcon fontSize="large" />
                            <label> <strong> CEP: </strong> {inf.cep} </label>
                        </div>

                        <div>
                            <LooksOneIcon fontSize="large" />
                            <label> <strong> Número: </strong> {inf.numero} </label>
                        </div>

                        <div>
                            <EditLocationIcon fontSize="large" />
                            <label> <strong> Complemento: </strong> {inf.complemento} </label>
                        </div>

                        <div>
                            <DirectionsIcon fontSize="large" />
                            <label> <strong> Logradouro: </strong> {inf.logradouro} </label>
                        </div>

                        <div>
                            <MapsHomeWorkIcon fontSize="large" />
                            <label> <strong> Bairro: </strong> {inf.bairro} </label>
                        </div>

                        <div>
                            <AccountBalanceOutlinedIcon fontSize="large" />
                            <label> <strong> Banco: </strong> {inf.banco} </label>
                        </div>

                        <div>
                            <CommentBankOutlinedIcon fontSize="large" />
                            <label> <strong> Agência: </strong> {inf.agencia} </label>
                        </div>

                        <div>
                            <AddCardOutlinedIcon fontSize="large" />
                            <label> <strong> Conta: </strong> {inf.conta} </label>
                        </div>

                        <div>
                            <SwitchAccountIcon fontSize="large" />
                            <label> <strong> Tipo de conta: </strong> {inf.tipoDeContaBancaria} </label>
                        </div>

                        {inf.tipoDePessoa === "Pessoa física" ? <div>
                            <PinIcon fontSize="large" />
                            <label> <strong> CPF: </strong> {inf.cpf} </label>
                        </div> : <div>
                            <PinIcon fontSize="large" />
                            <label> <strong> CNPJ: </strong> {inf.cnpj} </label>
                        </div>}

                        <div>
                            <MoneyIcon fontSize="large" />
                            <label> <strong> Código do banco: </strong> {inf.codigoDoBanco} </label>
                        </div>
                    </>
                ))}
            </form>
            <div className={styles.MidiaKit}>
                <button onClick={baixarPdf} className="btn btn-primary"> Visualizar mídia kit </button>
            </div>
        </section>
    )
}

